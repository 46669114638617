exports.components = {
  "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-about-page-layout-tsx": () => import("./../../../node_modules/@thepolicylab-projectportals/gatsby-theme-project-portal/src/layouts/AboutPageLayout.tsx" /* webpackChunkName: "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-about-page-layout-tsx" */),
  "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-card-page-layout-tsx": () => import("./../../../node_modules/@thepolicylab-projectportals/gatsby-theme-project-portal/src/layouts/CardPageLayout.tsx" /* webpackChunkName: "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-card-page-layout-tsx" */),
  "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-contact-page-layout-tsx": () => import("./../../../node_modules/@thepolicylab-projectportals/gatsby-theme-project-portal/src/layouts/ContactPageLayout.tsx" /* webpackChunkName: "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-contact-page-layout-tsx" */),
  "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-project-detail-page-tsx": () => import("./../../../node_modules/@thepolicylab-projectportals/gatsby-theme-project-portal/src/layouts/ProjectDetailPage.tsx" /* webpackChunkName: "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-project-detail-page-tsx" */),
  "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-search-page-layout-tsx": () => import("./../../../node_modules/@thepolicylab-projectportals/gatsby-theme-project-portal/src/layouts/SearchPageLayout.tsx" /* webpackChunkName: "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-search-page-layout-tsx" */),
  "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-thank-you-page-layout-tsx": () => import("./../../../node_modules/@thepolicylab-projectportals/gatsby-theme-project-portal/src/layouts/ThankYouPageLayout.tsx" /* webpackChunkName: "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-layouts-thank-you-page-layout-tsx" */),
  "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-pages-404-tsx": () => import("./../../../node_modules/@thepolicylab-projectportals/gatsby-theme-project-portal/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-thepolicylab-projectportals-gatsby-theme-project-portal-src-pages-404-tsx" */)
}

